import { createTheme } from "@mui/material";

export const colorBase = "#E59866";
export const colorBlue = "#0265F4";
export const colorBlueDark = "#001949";
export const colorRed = "#F54F64";
export const colorGrey = "#73757A";

const themeData = {
  palette: {
    primary: {
      main: `${colorBase}`,
      light: `${colorBase}`,
      dark: `${colorBase}`,
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff6333",
      main: "#ff3d00",
      dark: "#b22a00",
      contrastText: "#fff",
    },
    error: {
      light: colorBase,
      main: colorBase,
      dark: colorBase,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          text: "p",
          title: "h1",
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          ...(ownerState.variant === "text" && {
            fontSize: "13px",
            lineHeight: "15px",
          }),
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({}),
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 0,
        }),
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
};

export const theme = createTheme(themeData);
