import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  colorBase,
  colorBlue,
  colorBlueDark,
  theme,
} from "../Resources/Design/theme";
import logo from "../Resources/Design/logo.png";
import { MediaLinks } from "../Routes/Pages/MainPage";

const height = "60px";

const Icon = ({ icon, sx = {} }) => {
  const editedIcon = React.cloneElement(icon, {
    style: {
      height: "100%",
      maxWidth: "33px",
      fill: "white",
      color: "white",
      ...sx,
    },
  });
  return <>{editedIcon}</>;
};

const navigation = [
  { id: "products", name: "Produkty" },
  { id: "contact", name: "Kontakt" },
  { id: "suppliers", name: "Keramici" },
];

function Navbar({}) {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const location = useLocation();
  const [hovered, setHovered] = React.useState(null);
  const currentPath = location.pathname;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  return (
    <>
      <header>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            elevation={0}
            position="fixed"
            sx={{
              maxHeight: height,
              height: height,
              backgroundColor: elevated ? colorBase : "transparent",
              opacity: 0.95,
              boxShadow: elevated ? "0px 2px 5px 0px rgba(0,0,0,0.2)" : "none",
              ".MuiToolbar-root": {
                minHeight: height,
                maxHeight: height,
                height: height,
                px: { xs: "16px", sm: "24px" },
                maxWidth: 1200,
                width: "100%",
                mx: "auto",
                zIndex: 10,
                minWidth: "300px",
              },
            }}
          >
            <Toolbar>
              <Box
                component={Link}
                sx={{
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                  p: "5px",
                  pl: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                to={"/"}
              >
                <Box component={"img"} src={logo} sx={{ maxWidth: "28px" }} />
                <Typography
                  sx={{
                    ml: "8px",
                    mb: "1px",
                    height: "100%",
                    color: "black",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      marginRight: "-2px",
                      fontSize: "20px",
                    }}
                  >
                    M
                  </span>
                  <span>KERAMIKA</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!mobile &&
                    navigation.map((data, index) => (
                      <Button
                        key={"ButtonNavbar" + index}
                        id={"ButtonNavbarId" + index}
                        name={`ButtonNavbarN${index}`}
                        sx={{
                          mr: "8px",
                          backgroundColor: "transparent",
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                        tabIndex={index}
                        onClick={(e) => {
                          e.preventDefault();
                          if (e.target.name == hovered) {
                            setHovered(null);
                          }
                          const element = document.getElementById(data.id);
                          element.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                        onMouseEnter={(e) => {
                          setHovered(e.target.name);
                        }}
                        onMouseLeave={(e) => {
                          if (e.target.name == hovered) {
                            setHovered(null);
                          }
                        }}
                        onContextMenu={(e) => {
                          setHovered(null);
                        }}
                      >
                        <Typography
                          variant="navbar"
                          sx={{
                            transform:
                              hovered == `ButtonNavbarN${index}`
                                ? "translateY(-2px)"
                                : "translateY(0px)",
                            transitionDuration: "200ms",
                            color: "black",
                            fontWeight: "700",
                          }}
                        >
                          {data.name}
                        </Typography>
                      </Button>
                    ))}
                  <MediaLinks
                    sxInsta={{
                      width: "40px",
                      height: "40px",
                      mb: 0,
                      mr: "10px",
                      ml: "4px",
                    }}
                    sxFb={{ width: "40px", height: "40px" }}
                  />
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </header>
    </>
  );
}

export default Navbar;

const LogoSvg = ({ color = "#fff" }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width="24pt"
      height="24pt"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M7 12H17M8 8.5C8 8.5 9 9 10 9C11.5 9 12.5 8 14 8C15 8 16 8.5 16 8.5M8 15.5C8 15.5 9 16 10 16C11.5 16 12.5 15 14 15C15 15 16 15.5 16 15.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
