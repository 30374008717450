import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { colorBackground } from "../MainPage";

const openHours = [
  { text: "Po", time: "8:30 - 17:00" },
  { text: "Út", time: "8:30 - 17:00" },
  { text: "St", time: "8:30 - 17:00" },
  { text: "Čt", time: "8:30 - 17:00" },
  { text: "Pá", time: "8:30 - 17:00" },
  { text: "So", time: "Zavřeno" },
  { text: "Ne", time: "Zavřeno" },
];

export default function Contact() {
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: "0px",
        bgcolor: colorBackground,
        py: { xs: "16px", sm: "24px" },
        mt: "50px",
        textAlign: { xs: "center", sm: "left" },
      }}
      id="contact"
    >
      <Grid container spacing={3} columns={24}>
        <Grid item xs={24} sm={12} md={12}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "900",
              fontSize: { xs: "30px", sm: "34px" },

              lineHeight: { xs: "34px", sm: "38px" },
              pb: "20px",
              pt: { xs: "16px", sm: "4px" },
            }}
          >
            {`Otevírací doba`}
          </Typography>
          {openHours.map((data, i) => (
            <div key={"OpenHours" + i}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "16px",
                  minWidth: "36px",
                  width: "36px",
                  display: "inline-block",
                }}
              >
                {data.text}:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "16px",
                  display: "inline-block",
                }}
              >{`${data.time}`}</Typography>
            </div>
          ))}
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "900",
              fontSize: { xs: "18px", sm: "20px" },

              lineHeight: { xs: "22px", sm: "24px" },
              py: "12px",
            }}
          >
            {`Adresa`}
          </Typography>
          <Typography sx={{}}>
            Nerudova 320/6, 602 00
            <br />
            Brno-střed-Veveří
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "900",
              fontSize: { xs: "18px", sm: "20px" },

              lineHeight: { xs: "22px", sm: "24px" },
              py: "12px",
            }}
          >
            {`Email`}
          </Typography>
          <Typography sx={{ mb: { xs: "12px", sm: "0" } }}>
            m.keramika@email.cz
          </Typography>
        </Grid>
        <Grid item xs={24} sm={12} md={12}>
          <Box
            component="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.7302807998494!2d16.59028383025745!3d49.20567600219374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47129440f4b696d1%3A0x372388357cd3be36!2zTmVydWRvdmEgMzIwLzYsIDYwMiAwMCBCcm5vLXN0xZllZC1WZXZlxZnDrQ!5e0!3m2!1scs!2scz!4v1715766912643!5m2!1scs!2scz"
            sx={{
              border: "0",
              width: "100%",
              height: "450px",
              borderRadius: "12px",
            }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
