import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import bg from "../MainPage/bg.jpg";
import bgsun from "../MainPage/bgsun.png";
import { colorBackground } from "../MainPage";
import { theme } from "../../../Resources/Design/theme";

const Background = () => (
  <Box
    component="img"
    src={bg}
    sx={{
      zIndex: 1,
      position: "absolute",
      left: "0",
      top: "0",
      width: { xs: "480px", sm: "580px" },
      borderBottomRightRadius: "200px",
      borderBottomLeftRadius: "0px",
      pr: "40px",
    }}
  />
);

const Sun = () => (
  <Box
    component="img"
    src={bgsun}
    sx={{
      zIndex: 1,
      position: "absolute",
      right: { xs: 5, sm: 40, md: 60 },
      bottom: { xs: 5, sm: 40, md: 60 },
      width: { xs: "120px", sm: "180px" },
      borderRadius: "100%",
    }}
  />
);

export default function Intro() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        bgcolor: colorBackground,
        position: "relative",
        width: "100%",
        height: { xs: "317px", sm: "389px" },
        overflowX: "clip",
      }}
    >
      <Background />
      <Sun />
      <Box
        sx={{
          zIndex: 2,
          mt: { xs: "120px", sm: "140px" },
          display: "flex",
          flexDirection: "column",
          ml: { xs: "16px", sm: "24px" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "900",
            fontSize: { xs: "30px", sm: "34px" },
            textAlign: "left",
            lineHeight: { xs: "34px", sm: "38px" },
          }}
        >
          {`Originální výrobky`}
          {/* ${mobile ? "" : " keramické"} */}
        </Typography>
        <Typography
          sx={{
            mt: "10px",
            fontFamily: "Roboto",
            fontWeight: "500",
            textAlign: "left",
            fontSize: { xs: "18px", sm: "20px" },
            lineHeight: { xs: "20px", sm: "22px" },
            maxWidth: { xs: "270px", sm: "300px" },
          }}
        >
          Ideální dárky z keramiky
          <br /> pro vás a vaše blízké
        </Typography>
      </Box>
    </Box>
  );
}
