import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Intro from "./MainPage/Intro";
import Goodies from "./MainPage/Goodies";
import { useOutletContext } from "react-router-dom";
import Contact from "./MainPage/Contact";
import Suppliers from "./MainPage/Suppliers";

import { ReactComponent as Instagram } from "../../Resources/Design/Links/instagram.svg";
import { ReactComponent as Facebook } from "../../Resources/Design/Links/facebook.svg";

export const colorBackground = "#fdf6e3";

const LinkContainer = ({ children, sx = {}, link }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
      component="a"
      sx={{
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        bgcolor: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
        p: "8px",
        transition: "transform .2s ease-in-out",
        transform: hover ? "scale(1.08)" : "scale(1)",
        ...sx,
      }}
      href={link}
      target="_blank"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onMouseDown={() => {
        setHover(false);
      }}
    >
      {children}
    </Box>
  );
};

export const MediaLinks = ({ sxInsta = {}, sxFb = {} }) => (
  <>
    <LinkContainer
      sx={{ mb: "10px", ...sxInsta }}
      link="https://www.instagram.com/keramika_marta/"
    >
      <Instagram />
    </LinkContainer>
    <LinkContainer
      link="https://www.facebook.com/people/Keramika-Marta/61558983370715/"
      sx={{ ...sxFb }}
    >
      <Facebook />
    </LinkContainer>
  </>
);

const Links = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        left: 12,
        bottom: 15,
        display: "flex",
        flexDirection: "column",
        zIndex: 10,
      }}
    >
      <MediaLinks />
    </Box>
  );
};

export default function MainPage() {
  return (
    <>
      {/* <Links/> */}
      <Container maxWidth="lg" sx={{ px: "0px !important" }}>
        <Intro />
        <Goodies />
        <Contact />
        <Suppliers />
      </Container>
    </>
  );
}
