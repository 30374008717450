import * as React from "react";

import "./smooth_loader.css";
import { Backdrop, Box } from "@mui/material";

export default function LoadingBackdrop({ open, handleClose, sxProps = {} }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 99999999, //(theme) => theme.zIndex.drawer + 1,
        ...sxProps,
      }}
      open={open}
      onClick={handleClose}
    >
      <Box sx={{ width: "100%", height: "200px" }}>
        <div className="wrapper-bloader">
          <Box
            component={"span"}
            sx={{
              opacity: { xs: "0", sm: "1", md: "1" },
            }}
            className="circle-bload circle-bload-1"
          ></Box>

          <span className="circle-bload circle-bload-2"></span>
          <span className="circle-bload circle-bload-3"></span>
          <span className="circle-bload circle-bload-4"></span>
          <span className="circle-bload circle-bload-5"></span>
          <span className="circle-bload circle-bload-6"></span>
          <span className="circle-bload circle-bload-7"></span>

          <Box
            component={"span"}
            sx={{
              opacity: { xs: "0", sm: "1", md: "1" },
            }}
            className="circle-bload circle-bload-8"
          ></Box>
        </div>
      </Box>
    </Backdrop>
  );
}
