import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../Resources/Design/theme";

import dog from "../../../Resources/Design/Goodies/dog.jpg";
import hedge from "../../../Resources/Design/Goodies/hedge.jpg";
import sun from "../../../Resources/Design/Goodies/sun.jpg";

import kettle from "../../../Resources/Design/Goodies/kettle.jpg";
import plating from "../../../Resources/Design/Goodies/plating.jpg";
import pots from "../../../Resources/Design/Goodies/pots.jpg";

import shelves from "../../../Resources/Design/Goodies/shelves.jpg";
import wall from "../../../Resources/Design/Goodies/wall.jpg";
import shop from "../../../Resources/Design/Goodies/shop.jpg";

export default function Goodies() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const firstImages = mobile
    ? [
        { alt: "dog", src: dog },
        { alt: "hedge", src: hedge },
        { alt: "kettle", src: kettle },
        { alt: "plating", src: plating },
      ]
    : [
        { alt: "dog", src: dog },
        { alt: "hedge", src: hedge },
        { alt: "sun", src: sun },
        { alt: "kettle", src: kettle },
        { alt: "plating", src: plating },
        { alt: "pots", src: pots },
      ];

  const secondImages = [
    { alt: "shelves", src: shelves },
    { alt: "shop", src: shop },
    { alt: "wall", src: wall },
  ];

  return (
    <Container maxWidth="lg" sx={{ position: "relative" }}>
      <div style={{ position: "absolute", top: -50 }} id="products" />
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontWeight: "900",
          fontSize: { xs: "30px", sm: "34px" },
          textAlign: "center",
          lineHeight: { xs: "34px", sm: "38px" },
          pt: "50px",
        }}
      >
        {`Naše produkty`}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            mt: "5px",
            fontFamily: "Roboto",
            fontWeight: "500",
            textAlign: "center",
            fontSize: { xs: "16px", sm: "17px" },
            lineHeight: { xs: "18px", sm: "19px" },
            mx: "20px",
            pb: "48px",
          }}
        >
          {`Nabízíme užitkovou i dekorativní keramiku`}
        </Typography>
      </Box>
      <Grid container spacing={3} columns={24}>
        {firstImages.map((data, i) => (
          <Grid item xs={24} sm={12} md={8} key={"GoodieImage" + i}>
            <Box
              component="img"
              src={data.src}
              alt={data.alt}
              sx={{ width: "100%", borderRadius: "12px" }}
              loading="lazy"
            />
          </Grid>
        ))}

        <Grid
          item
          xs={24}
          sx={{
            pt: "50px !important",
            pb: "32px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              mt: "0px",
              fontFamily: "Roboto",
              fontWeight: "700",
              textAlign: "center",
              fontSize: { xs: "18px", sm: "20px" },
              lineHeight: { xs: "20px", sm: "22px" },
              maxWidth: { xs: "280px", sm: "320px" },
            }}
          >
            {`Tyto produkty a mnohem více naleznete u nás na prodejně`}
          </Typography>
        </Grid>
        {secondImages.map((data, i) => {
          if (tablet && i == 2) return <></>;
          return (
            <Grid item xs={24} sm={12} md={8} key={"GoodieImage" + i}>
              <Box
                component="img"
                src={data.src}
                alt={data.alt}
                sx={{ width: "100%", borderRadius: "12px" }}
                loading="lazy"
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
