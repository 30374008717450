import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { colorBase, theme } from "../Resources/Design/theme";
import { EmailRounded } from "@mui/icons-material";
import { MediaLinks } from "../Routes/Pages/MainPage";

export default function Footer() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <div style={{ zIndex: 5 }}>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 5,
          width: "100%",
          height: "120px",
          bgcolor: colorBase,
          // boxShadow: "0px -1px 4px 0px rgba(187,187,187,1)",
          //WebkitBoxShadow: "0px -1px 2px 0px rgba(187,187,187,1)",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            width: "100%",
            height: "100%",
            py: "20px",
            px: "16px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              mr: "auto",
              width: "fit-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Typography
              component="a"
              href="/"
              sx={{
                color: "black",
                fontSize: "17px",
                textDecoration: "none",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              <span style={{ marginRight: "-2px", color: "white" }}>m</span>
              keramika.cz
            </Typography>

            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "13px",
                  textDecoration: "none",
                  fontWeight: "normal",
                  minWidth: "115px",
                }}
              >
                {mobile
                  ? "Nerudova 320/6"
                  : "MKERAMIKA | Adresa: Nerudova 320/6, 602 00 Brno | Mgr. Marta Hubková"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              ml: "auto",
              width: "fit-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              alignItems: "flex-end",
              alignContent: "flex-end",
            }}
          >
            <Typography
              component="a"
              href="/ochrana"
              sx={{
                color: "black",
                fontSize: "14px",
                textDecoration: "none",
                fontWeight: "bold",
                minWidth: { xs: "115px", sm: "166px" },
                pl: { xs: "12px", sm: "0" },
              }}
            >
              Ochrana soukromí
            </Typography>

            <Box
              sx={{
                mt: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                alignContent: "center",
                width: "fit-content",
                whiteSpace: "nowrap",
                pl: "4px",
                ml: "14px",
              }}
            >
              <MediaLinks
                sxInsta={{
                  width: "40px",
                  height: "40px",
                  mb: 0,
                  mr: "10px",
                  ml: "4px",
                }}
                sxFb={{ width: "40px", height: "40px" }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
