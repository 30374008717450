import { Container } from "@mui/material";
import React from "react";
import { colorBase } from "../../Resources/Design/theme";

const titleStyle = { fontWeight: "bold", fontSize: "32px" };
const smallTitleStyle = { fontWeight: "bold", fontSize: "16px" };
const weightTextStyle = { fontWeight: "bold", fontSize: "14px" };

export default function () {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: colorBase,
          marginRight: "auto",
          marginLeft: "auto",
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ textAlign: "left", mb: "40px", minHeight: "100vh" }}
      >
        <br />
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
          Informace o zpracování osobních údajů
        </span>
        <br />
        <br />
        Nezpracováváme žádné vaše osobní údaje ve smyslu zákona č. 101/2000 Sb.,
        o ochraně osobních údajů a o změně některých zákonů, ve znění pozdějších
        předpisů a nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne
        27. dubna 2016, o ochraně fyzických osob v souvislosti se zpracováním
        osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice
        95/46/ES (obecné nařízení o ochraně osobních údajů).
      </Container>
    </div>
  );
}
