import { Box, Container, Typography } from "@mui/material";
import React from "react";
import bgend from "./bgend.jpg";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

export default function Suppliers() {
  const suppliersList = [
    { name: "Ludmila Vlčková", web: "https://www.facebook.com/Ludmilavlckova" },
    { name: "Palíškovi", web: "https://keramikarna.cz/" },
    { name: "Marta Hubková", web: "https://mkeramika.cz/" },
    { name: "Petr Tengler", web: "http://keramikaukotatka.cz/" },
    { name: "Bohumila Najvarová", web: "" },
    { name: "Petr Bílek", web: "https://keramikabilek.cz/" },
    { name: "Miroslav Ivanov", web: "http://www.lmkeramika.com/" },
    { name: "Pavlína Palme", web: "http://www.lmkeramika.com/" },
    { name: "Tomáš Vágner", web: "" },
    { name: "Ivana Marišlerová", web: "" },
    { name: "Milan Libiš", web: "" },
    { name: "Ilona Listopadová", web: "" },
  ];

  const WebIcon = ({ link }) => (
    <Box
      component={"a"}
      sx={{
        width: "24px",
        height: "24px",
        borderRadius: "100%",
        cursor: "pointer",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        "&:hover": {
          transform: "scale(1.02)",
          transition: "transform .2s ease-in-out",
        },
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.4)",
      }}
      href={link}
      target="_blank"
    >
      <LanguageRoundedIcon
        sx={{ color: "black", width: "22px", height: "22px" }}
      />
    </Box>
  );

  const Background = () => (
    <Box
      component="img"
      src={bgend}
      sx={{
        zIndex: 0,
        position: "absolute",
        right: "0",
        //top: { xs: 228, sm: 188 },
        bottom: -2,
        width: { xs: "500px", sm: "580px" },
        borderTopLeftRadius: "200px",
        pl: "40px",
      }}
    />
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ position: "relative" }}>
        <div style={{ position: "absolute", top: -50 }} id="suppliers" />
        <Background />
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "900",
            fontSize: { xs: "30px", sm: "34px" },
            textAlign: "center",
            lineHeight: { xs: "34px", sm: "38px" },
            py: "50px",
          }}
        >
          {`Naši keramici`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            zIndex: 6,
            pb: "25px",
          }}
        >
          {suppliersList.map((data) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                zIndex: 6,
                alignContent: "center",
                alignItems: "center",
                pb: "6px",
              }}
            >
              <Typography
                sx={{ mr: "8px", fontFamily: "Roboto", fontWeight: "bold" }}
              >
                {data.name}
              </Typography>
              {/*data.web && <WebIcon link={data.web} />*/}
            </Box>
          ))}
        </Box>
      </Container>
    </>
  );
}
