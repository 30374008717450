import "./App.css";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import LoadingBackdrop from "../Components/LoadingBackdrop/LoadingBackdrop";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../Resources/Design/theme";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import MainPage from "./Pages/MainPage";
import Footer from "../Components/Footer";
import OsobniUdaje from "./Pages/OsobniUdaje";

function RouteControl() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={{ minHeight: "100vh", minWidth: "300px" }}>
        <Navbar />
        <main style={{ zIndex: 2 }}>
          <Outlet />
        </main>
        <footer style={{ zIndex: 5 }}>
          <Footer />
        </footer>
      </div>
    </ThemeProvider>
  );
}

const router = createBrowserRouter([
  {
    element: <RouteControl />,
    errorElement: <></>,
    children: [
      {
        path: "*",
        element: <MainPage />,
      },
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/ochrana",
        element: <OsobniUdaje />,
      },
    ],
  },
]);

function App() {
  return (
    <Suspense fallback={<LoadingBackdrop open={true} />}>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
